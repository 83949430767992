import React, { useEffect, useState } from "react";
import useWindowSize from "../../components/WinSize";

// Assets
import Logo from "../../assets/images/FishLog Trace.svg";
import CrabMeat from "../../assets/images/crab meat.png";

// Utils
import DeliveryInformation from "./deliveryInformation";
import SupplierInformation from "./SupplierInformation";
import Sustainable from "./sustainable";
import ProductInformation from "./productInformation";
import SeafoodJourney from "./SeafoodJourney";

const CardProduct = ({ data }) => {
  const [winWidth] = useWindowSize();

  const dataTrace = data?.traceability_products;
  const [pagin, setPagin] = useState({
    start: 0,
    end: 1
  });

  const [pagination, setPagination] = useState(1);

  const [suppInfo, setSuppInfo] = useState();

  const paginate = () => {
    setSuppInfo(dataTrace.slice(pagin.start, pagin.end));
  };

  const handleBack = () => {
    if (pagination > 1) {
      setPagination(pagination - 1);
      setPagin({
        start: pagin.start - 1,
        end: pagin.end - 1
      });
    }
  };

  const handleNext = () => {
    if (pagination < dataTrace?.length) {
      setPagination(pagination + 1);
      setPagin({
        start: pagin.start + 1,
        end: pagin.end + 1
      });
    }
  };

  useEffect(() => {
    paginate();
  }, [pagin.start, pagin.end]);

  return (
    <div className="card-product">
      <div className="card-product-container">
        {winWidth >= 560 && (
          <div style={{ position: "relative" }}>
            <div
              style={{
                position: "absolute",
                top: "18px",
                right: "20px"
              }}
            >
              <div className="d-flex flex-row justify-content-between">
                <div className="pagination-info me-3">
                  <p className="text">
                    <span>{pagination}</span> / {dataTrace?.length}
                  </p>
                </div>

                <div className="d-flex justify-content-between">
                  <div
                    className={`pagination-button me-2 ${
                      pagination === 1 ? "disable" : ""
                    }`}
                    onClick={handleBack}
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10 13.1667L5.33333 8.50001L10 3.83334"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>

                  <div
                    className={`pagination-button ${
                      pagination === dataTrace?.length ? "disable" : ""
                    }`}
                    onClick={handleNext}
                  >
                    <svg
                      width="16"
                      height="17"
                      viewBox="0 0 16 17"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M6 3.83332L10.6667 8.49999L6 13.1667"
                        stroke="currentColor"
                        strokeWidth="2"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>
                  </div>
                </div>

                <div></div>
              </div>
            </div>
          </div>
        )}

        <div className="card-product-header">
          <div className="d-flex align-items-center w-100 justify-content-center">
            <img height="40px" src={Logo} alt="logo fishlog" />
          </div>
        </div>

        <div className="card-product-body">
          {suppInfo && (
            <>
              <ProductInformation
                data={data}
                CrabMeat={CrabMeat}
                dataTrace={suppInfo[0]}
                pagination={pagination}
                handleBack={handleBack}
                handleNext={handleNext}
                dataLength={dataTrace?.length}
              />

              <SupplierInformation data={data} dataTrace={suppInfo[0]} />
            </>
          )}

          {suppInfo && data.toggle_sustainability_program && (
            <Sustainable data={data} />
          )}

          <SeafoodJourney data={data} />

          <DeliveryInformation data={data} />
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
