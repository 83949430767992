import React from "react";
import { Button } from "react-bootstrap";

const ButtonNextPrev = ({
  type,
  data,
  pagin,
  paginStart,
  paginEnd,
  setPagin,
  setArr
}) => {
  const length = data.length;
  const paginate = () => {
    setArr(data.slice(paginStart, paginEnd));
  };

  return (
    <>
      <Button
        style={{
          margin: "10px 10px 0px 0px",
          borderRadius: "10px",
          padding: "0px 15px 0px 15px",
          width: "100px"
        }}
        variant={
          type == "mobile"
            ? paginStart == 0
              ? "secondary"
              : "primary"
            : paginStart == 0
              ? "outline-primary"
              : "primary"
        }
        onClick={() => {
          setPagin({
            ...pagin,
            start: paginStart - (type == "mobile" ? 1 : 5),
            end: paginEnd - (type == "mobile" ? 1 : 5)
          });
          paginate();
        }}
        disabled={paginStart == 0}
      >
        Previous
      </Button>
      <Button
        style={{
          margin: "10px 0px 0px 0px",
          borderRadius: "10px",
          padding: "5px 15px 5px 15px",
          width: "100px"
        }}
        variant={
          type == "mobile"
            ? paginEnd >= length
              ? "secondary"
              : "primary"
            : paginEnd >= length
              ? "outline-primary"
              : "primary"
        }
        onClick={() => {
          setPagin({
            ...pagin,
            start: paginStart + (type == "mobile" ? 1 : 5),
            end: paginEnd + (type == "mobile" ? 1 : 5)
          });
          paginate();
        }}
        disabled={paginEnd >= length}
      >
        Next
      </Button>
    </>
  );
};

export default ButtonNextPrev;
