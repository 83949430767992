import React from "react";
import { Card, Row, Col } from "react-bootstrap";

import CertificationData from "./Component/certificationData";
import ManufactureData from "./Component/manufacturerData";
import QcReportData from "./Component/qcReportData";
import NutritionFactsData from "./Component/nutritionFactsData";

const Manufacturer = ({ data, dataTrace }) => {
  return (
    <Card className="mt-3 w-100">
      <Card.Body className="p-3">
        <Row className="product-info">
          <Col lg={6}>
            <p className="title">Manufacturer Information</p>
            <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
            <ManufactureData data={data} dataTrace={dataTrace} />
          </Col>

          {data?.manufacturer_certification && (
            <Col lg={6}>
              <p className="semi-title mt-sm-3 mt-lg-0">Certification</p>
              <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
              <p className="text-6">
                Certification is an indication of the product&apos;s compliance
                with the required standards.
              </p>
              <CertificationData data={data} />
            </Col>
          )}

          {dataTrace?.nutrition_facts && (
            <Col lg={6}>
              <p className="title" style={{ marginTop: "20px" }}>
                Nutrition Facts
              </p>
              <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
              <NutritionFactsData dataTrace={dataTrace} />
            </Col>
          )}

          {dataTrace?.qc_report && (
            <Col lg={6}>
              <p className="title" style={{ marginTop: "20px" }}>
                QC Report
              </p>
              <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
              <QcReportData dataTrace={dataTrace} />
            </Col>
          )}
        </Row>
      </Card.Body>
    </Card>
  );
};

export default Manufacturer;
