import React from "react";
import { Row } from "react-bootstrap";

const NutritionFactsData = ({ dataTrace }) => {
  return (
    <Row className="list-text">
      {dataTrace.nutrition_facts && (
        <div style={{ marginBottom: "5px" }}>
          <a
            href={dataTrace.nutrition_facts}
            target="_blank"
            rel="noopener noreferrer"
          >
            Click for details <i className="bx bx-link-external"></i>
          </a>
        </div>
      )}
    </Row>
  );
};

export default NutritionFactsData;
