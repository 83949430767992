import React, { useEffect, useState } from "react";
import { Card, Row, Col } from "react-bootstrap";
import FishPicture from "../../components/Image/FishPicture";
import useWindowSize from "../../components/WinSize";

import CertificationData from "./Component/certificationData";
import ManufactureData from "./Component/manufacturerData";
import QcReportData from "./Component/qcReportData";
import NutritionFactsData from "./Component/nutritionFactsData";

import Manufacturer from "./manufacturer";

const ProductInformation = ({
  data,
  dataTrace,
  CrabMeat,
  pagination,
  handleBack,
  handleNext,
  dataLength
}) => {
  const [selectedImg, setSelectedImg] = useState("");
  const [imageArray, setImageArray] = useState([]);
  const [winWidth] = useWindowSize();

  useEffect(() => {
    if (dataTrace.product_images?.length) {
      const imgArray = dataTrace.product_images.map((item) =>
        item.replace("tr:h-120px", "tr:h-350px")
      );

      const filterArr = imgArray.filter(
        (item) => !item.includes("36c7893adf31")
      );
      setImageArray(filterArr);
      setSelectedImg(filterArr[0]);
    }
  }, [dataTrace.product_images]);

  return (
    <>
      {winWidth <= 560 ? (
        <>
          <div className="d-flex justify-content-between align-items-center w-100 mb-3">
            <p className="title-head">Product Information</p>

            <div className="d-flex justify-content-between">
              <div className="pagination-info me-3">
                <p className="text">
                  <span>{pagination}</span> / {dataLength}
                </p>
              </div>

              <div className="d-flex justify-content-between">
                <div
                  className={`pagination-button me-2 ${
                    pagination === 1 ? "disable" : ""
                  }`}
                  onClick={handleBack}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 13.1667L5.33333 8.50001L10 3.83334"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>

                <div
                  className={`pagination-button ${
                    pagination === dataLength ? "disable" : ""
                  }`}
                  onClick={handleNext}
                >
                  <svg
                    width="16"
                    height="17"
                    viewBox="0 0 16 17"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6 3.83332L10.6667 8.49999L6 13.1667"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </div>
            </div>
          </div>

          <Row className="product-info">
            <Col lg={12}>
              <div className="photo">
                {dataTrace.product_images.length ? (
                  <div>
                    <FishPicture
                      selectedImg={selectedImg}
                      setSelectedImg={setSelectedImg}
                      pictureArray={imageArray || []}
                    />
                  </div>
                ) : (
                  <>
                    <img
                      src={data.attachment ?? CrabMeat}
                      alt="product photos"
                      className="image"
                    />
                  </>
                )}
              </div>
              <p className="title">{dataTrace?.product}</p>
              <p className="subtitle">
                {dataTrace.product_type} - {dataTrace.process}
              </p>
              <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
            </Col>
          </Row>

          <Card className="w-100">
            <p className="title-head">Product Information</p>
            <Card.Body className="p-3">
              <Row>
                <Row className="list-text">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    Tracebility Code
                  </Col>
                  <Col>
                    <span style={{ marginRight: "2px" }}>:</span>{" "}
                    {data.traceability_code ? data?.traceability_code : "-"}
                  </Col>
                </Row>
                <Row className="list-text">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    Product Code
                  </Col>
                  <Col>
                    <span style={{ marginRight: "2px" }}>:</span>{" "}
                    {data.toggle_product_code
                      ? dataTrace.product_code
                        ? dataTrace?.product_code
                        : "-"
                      : "Not Applicable"}
                  </Col>
                </Row>

                <Row className="list-text">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    Product
                  </Col>
                  <Col>
                    <span style={{ marginRight: "2px" }}>:</span>{" "}
                    {dataTrace.product ? dataTrace.product : "-"}
                  </Col>
                </Row>
                <Row className="list-text">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    Type
                  </Col>
                  <Col>
                    <span style={{ marginRight: "2px" }}>:</span>{" "}
                    {dataTrace.product_type ? dataTrace.product_type : "-"}
                  </Col>
                </Row>
                <Row className="list-text">
                  <Col lg={5} md={5} sm={5} xs={5}>
                    Item Name
                  </Col>
                  <Col>
                    <span style={{ marginRight: "2px" }}>:</span>{" "}
                    {dataTrace.item_name ? dataTrace?.item_name : "-"}
                  </Col>
                </Row>

                <Row className="product-info">
                  <Col lg={6}>
                    <p className="title-mobile">Distributor Information</p>

                    <Row className="list-text">
                      <Col
                        lg={12}
                        md={12}
                        sm={12}
                        xs={12}
                        style={{
                          alignItems: "center",
                          display: "flex",
                          justifyContent: "center",
                          margin: "0px 0px 8px 0px"
                        }}
                      >
                        <img
                          src={dataTrace?.image}
                          className="image-distributor"
                          alt="distribution-info"
                        />
                      </Col>
                      <Col lg={4} md={4} sm={4} xs={4}>
                        Name
                      </Col>
                      <Col>
                        <span style={{ marginRight: "2px" }}>:</span>{" "}
                        {dataTrace.name ? dataTrace?.name : "-"}
                      </Col>
                    </Row>
                    <Row className="list-text">
                      <Col lg={4} md={4} sm={4} xs={4}>
                        Location
                      </Col>
                      <Col>
                        <span style={{ marginRight: "2px" }}>:</span>{" "}
                        {dataTrace.location ? dataTrace?.location : "-"}
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>

              <Row className="product-info">
                <Col lg={6}>
                  <p className="title-mobile">Manufacturer Information</p>
                  <ManufactureData data={data} dataTrace={dataTrace} />
                </Col>

                {data?.manufacturer_certification && (
                  <Col lg={6}>
                    <p className="title-mobile">Certification</p>
                    <p className="text-6">
                      Certification is an indication of the product&apos;s
                      compliance with the required standards.
                    </p>
                    <CertificationData data={data} />
                  </Col>
                )}

                {dataTrace?.nutrition_facts && (
                  <Col lg={6}>
                    <p className="title-mobile">Nutrition Facts</p>
                    <NutritionFactsData dataTrace={dataTrace} />
                  </Col>
                )}

                {dataTrace?.qc_report && (
                  <Col lg={6}>
                    <p className="title-mobile">QC Report</p>
                    <QcReportData dataTrace={dataTrace} />
                  </Col>
                )}
              </Row>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <Card className="mt-3 mb-3 w-100">
            <p className="title-head">Product Information</p>
            <Card.Body className="p-3">
              <Row className="product-info">
                <Col lg={6}>
                  <div className="photo">
                    {dataTrace?.product_images ? (
                      <div>
                        <FishPicture
                          selectedImg={selectedImg}
                          setSelectedImg={setSelectedImg}
                          pictureArray={imageArray || []}
                        />
                      </div>
                    ) : (
                      <img
                        src={data.attachment ?? CrabMeat}
                        alt="product photos"
                        className="image"
                      />
                    )}
                  </div>
                </Col>

                <Col lg={6}>
                  <p className="title">{dataTrace?.product}</p>
                  <p className="subtitle">
                    {dataTrace.product_type} - {dataTrace.process}
                  </p>
                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Tracebility Code
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {data.traceability_code ? data?.traceability_code : "-"}
                    </Col>
                  </Row>

                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Product Code
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {data.toggle_product_code
                        ? dataTrace.product_code
                          ? dataTrace?.product_code
                          : "-"
                        : "Not Applicable"}
                    </Col>
                  </Row>

                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Product
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {dataTrace.product ? dataTrace?.product : "-"}
                    </Col>
                  </Row>

                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Type
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {dataTrace.product_type ? dataTrace?.product_type : "-"}
                    </Col>
                  </Row>

                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Item Name
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {dataTrace.item_name ? dataTrace?.item_name : "-"}
                    </Col>
                  </Row>

                  <p className="title" style={{ marginTop: "20px" }}>
                    Distributor Information
                  </p>

                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
                  <Row className="list-text">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      {dataTrace.image && (
                        <img
                          src={dataTrace?.image}
                          className="image-distributor"
                          alt="distribution-info"
                        />
                      )}
                    </Col>
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Name
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {dataTrace.name ? dataTrace?.name : "-"}
                    </Col>
                  </Row>

                  <Row className="list-text">
                    <Col lg={4} md={4} sm={4} xs={4}>
                      Location
                    </Col>
                    <Col>
                      <span style={{ marginRight: "2px" }}>:</span>{" "}
                      {dataTrace.location ? dataTrace?.location : "-"}
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <Manufacturer data={data} dataTrace={dataTrace} />
        </>
      )}
    </>
  );
};

export default ProductInformation;
