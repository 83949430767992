import React from "react";
import { Col, Row } from "react-bootstrap";

const ManufactureData = ({ data, dataTrace }) => {
  return (
    <>
      <Row className="list-text">
        <Col lg={4} md={4} sm={4} xs={4}>
          Name
        </Col>
        <Col>
          <span style={{ marginRight: "2px" }}>:</span>{" "}
          {data.name ? data?.name : "-"}
        </Col>
      </Row>
      <Row className="list-text">
        <Col lg={4} md={4} sm={4} xs={4}>
          Location
        </Col>
        <Col>
          <span style={{ marginRight: "2px" }}>:</span>{" "}
          {data.location ? data?.location : "-"}
        </Col>
      </Row>
      <Row className="list-text">
        <Col lg={4} md={4} sm={4} xs={4}>
          Process
        </Col>
        <Col>
          <span style={{ marginRight: "2px" }}>:</span>{" "}
          {dataTrace.process ? dataTrace?.process : "-"}
        </Col>
      </Row>
      <Row className="list-text">
        <Col lg={4} md={4} sm={4} xs={4}>
          Packaging
        </Col>
        <Col>
          <span style={{ marginRight: "2px" }}>:</span>{" "}
          {dataTrace.packaging ? dataTrace?.packaging : "-"}
        </Col>
      </Row>
    </>
  );
};

export default ManufactureData;
