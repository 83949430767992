import React from "react";
import { Card, Row, Col } from "react-bootstrap";
import useWindowSize from "../../components/WinSize";

const Sustainable = ({ data }) => {
  const [winWidth] = useWindowSize();

  return (
    <>
      {winWidth <= 560 ? (
        <>
          <Card className="mt-3 mb-3 w-100 card-border">
            <Card.Body className="p-3">
              <Row className="product-info">
                <Col lg={6}>
                  <p className="title" style={{ marginTop: "20px" }}>
                    Sustainability Program
                  </p>

                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
                  <Row className="list-text">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div>
                        <p style={{ margin: "8px 0px 8px 0px" }}>
                          We are committed to stop illegal fishing globally
                        </p>
                        <img
                          src={data?.banner}
                          className="image-distributor"
                          alt="sustainability"
                        />
                        <a
                          href={data?.supporting_file}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          Link Report <i className="bx bx-link-external"></i>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      ) : (
        <>
          <Card className="mt-3 mb-3 w-100">
            <Card.Body className="p-3">
              <Row className="product-info">
                <Col lg={6}>
                  <p className="title" style={{ marginTop: "20px" }}>
                    Sustainability Program
                  </p>

                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
                  <Row className="list-text">
                    <Col lg={12} md={12} sm={12} xs={12}>
                      <div>
                        <p style={{ margin: "8px 0px 8px 0px" }}>
                          We are committed to stop illegal fishing globally
                        </p>
                        <img
                          src={data?.banner}
                          className="image-distributor"
                          alt="sustainability"
                        />
                        <a
                          href={data?.supporting_file}
                          target="_blank"
                          rel="noreferrer"
                        >
                          Link Report <i className="bx bx-link-external"></i>
                        </a>
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </>
      )}
    </>
  );
};

export default Sustainable;
