import React from "react";
import { Card, Placeholder } from "react-bootstrap";

const Skeleton = () => {
  return (
    <div
      className="d-flex justify-content-center"
      style={{ marginTop: "32px", marginBottom: "80px" }}
    >
      <Card style={{ width: "375px", border: "none" }}>
        <Card.Body>
          <Placeholder as={Card.Title} animation="glow">
            <Placeholder xs={12} className="mb-3" />
          </Placeholder>
          <Placeholder
            style={{
              width: "100%",
              height: "180px",
              borderRadius: "20px",
              backgroundColor: "gray",
              marginBottom: "20px"
            }}
          />
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={5} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={8} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={6} />
          </Placeholder>
          <Placeholder as={Card.Text} animation="glow">
            <Placeholder xs={4} /> <Placeholder xs={7} />
          </Placeholder>
        </Card.Body>
      </Card>
    </div>
  );
};

export default Skeleton;
