import React from "react";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";

import Logo from "../../assets/images/fishlog.svg";

function NavbarPublic() {
  const linkToEmail = () => {
    window.open("mailto:hello@fishlog.co.id", "_blank");
  };

  return (
    <div
      className="d-flex"
      style={{
        position: "fixed",
        height: "70px",
        width: "100%",
        padding: "10px 20px",
        background: "#FFF",
        boxShadow: "0px 1px 0px 0px #DDE0E5",
        zIndex: "999"
      }}
    >
      <div className="d-flex align-items-center w-100 justify-content-between">
        <Link to="https://fishlog.co.id/">
          <img height="30px" src={Logo} alt="logo fishlog" />
        </Link>
        <Button variant="outline-primary" onClick={linkToEmail}>
          Help?
        </Button>
      </div>
    </div>
  );
}

export default NavbarPublic;
