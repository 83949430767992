import axios from "axios";
import { removeLocalstorage } from "../utils";

class Service {
  constructor() {
    let service = axios.create();
    service.interceptors.response.use(this.handleSuccess, this.handleError);
    this.service = service;
    this.token = localStorage.getItem("token");
  }

  handleSuccess(response) {
    return response;
  }

  handleError = (error) => {
    console.log("-ERROR-", error);
    // handle network error
    if (
      error?.message === "Network request failed" ||
      error?.message === "Network Error"
    ) {
      alert(
        "Network Error!",
        "Silakan periksa koneksi Anda dan coba kembali.",
        "iconNoInet"
      );
    }

    // handle error access
    if (error.response && error.response.status === 401) {
      removeLocalstorage();
    }
    return Promise.reject(error);
  };

  getWToken(token, path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Token ${token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  get(path, params, callback) {
    return this.service
      .get(path, {
        params,
        headers: {
          Authorization: `Token ${this.token}`
        }
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }

  getPublic(path, isAuth, callback) {
    const auth = () =>
      isAuth
        ? {
            headers: {
              Authorization: `Token ${this.token}`
            }
          }
        : {};
    return this.service
      .get(path, {
        ...auth()
      })
      .then((response) =>
        callback ? callback(response.status, response.data) : response
      );
  }
}

export default new Service();
