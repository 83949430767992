import React from "react";
import { Fragment, memo } from "react";

const FishPicture = ({ selectedImg, setSelectedImg, pictureArray }) => {
  return (
    <Fragment>
      {selectedImg ? (
        <div className="photo-parent">
          <img src={selectedImg} alt="picture-parent" className="image" />
        </div>
      ) : null}

      <div style={{ display: "block flex" }}>
        {pictureArray?.map((item, idx) => (
          <div key={idx} className="photo-child">
            <img
              src={item}
              alt="picture-child"
              onClick={() => setSelectedImg(item)}
              onMouseEnter={() => setSelectedImg(item)}
              width={"contain"}
              className="image"
            />
          </div>
        ))}
      </div>
    </Fragment>
  );
};

export default memo(FishPicture);
