import React from "react";
import { Card } from "react-bootstrap";

// Assets
import Transfer from "../../assets/images/icons/transfer.svg";

const DeliveryInformation = ({ data }) => {
  return (
    <>
      <p className="title-head mb-3 mt-3">Delivery Information</p>
      <Card className="w-100 card-border">
        <Card.Body className="p-3 delivery-information">
          <div className="d-flex flex-column align-items-center align-items-md-start">
            <p className="subtitle">Origin</p>
            <p className="text-2">
              {data.port_of_departure_code ?? data.departure_code}
            </p>
          </div>
          <div className="d-flex flex-column align-items-center">
            <img src={Transfer} alt="Transfer" />
            <p className="subtitle mt-2">{data.vessel ?? data.vessel_code}</p>
          </div>
          <div className="d-flex flex-column align-items-center align-items-md-end">
            <p className="subtitle">Destination</p>
            <p className="text-2">
              {data.port_of_destination_code ?? data.arrival_code}
            </p>
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

export default DeliveryInformation;
