import React from "react";
import { MarkerF } from "@react-google-maps/api";

import Default from "../../assets/images/pin/default.svg";
import Catching from "../../assets/images/pin/catching.svg";
import Departure from "../../assets/images/pin/departure.svg";
import Destination from "../../assets/images/pin/destination.svg";
import Landing from "../../assets/images/pin/landing.svg";
import Manufacture from "../../assets/images/pin/manufacture.svg";
import Process from "../../assets/images/pin/process.svg";
import Storage from "../../assets/images/pin/storage.svg";

import CatchingBig from "../../assets/images/pin/catching big.svg";
import DepartureBig from "../../assets/images/pin/departure big.svg";
import DestinationBig from "../../assets/images/pin/destination big.svg";
import LandingBig from "../../assets/images/pin/landing big.svg";
import ManufactureBig from "../../assets/images/pin/manufacture big.svg";
import ProcessBig from "../../assets/images/pin/process big.svg";
import StorageBig from "../../assets/images/pin/storage big.svg";

const PinIcon = (props) => {
  const { type, isBig = false } = props;

  return (
    <MarkerF
      visible={true}
      anchor={{ x: 10, y: -20 }}
      icon={
        isBig
          ? {
              url:
                type === "catching"
                  ? CatchingBig
                  : type === "departure"
                    ? DepartureBig
                    : type === "destination"
                      ? DestinationBig
                      : type === "landing"
                        ? LandingBig
                        : type === "manufacture"
                          ? ManufactureBig
                          : type === "process"
                            ? ProcessBig
                            : type === "storage"
                              ? StorageBig
                              : Default
            }
          : {
              url:
                type === "catching"
                  ? Catching
                  : type === "departure"
                    ? Departure
                    : type === "destination"
                      ? Destination
                      : type === "landing"
                        ? Landing
                        : type === "manufacture"
                          ? Manufacture
                          : type === "process"
                            ? Process
                            : type === "storage"
                              ? Storage
                              : Default
            }
      }
      {...props}
    ></MarkerF>
  );
};

export default PinIcon;
