import React from "react";
import { Card, Row } from "react-bootstrap";
import SupplierTables from "./SupplierTables";

const SupplierInformation = ({ data, dataTrace }) => {
  return (
    <Card className="mb-3 w-100 mt-3">
      <Card.Body className="p-3">
        <Row className="product-info">
          <p className="title" style={{ marginBottom: "20px" }}>
            Supplier Information
          </p>

          <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
          <div className="list-text">
            <SupplierTables data={data} dataTrace={dataTrace} />
          </div>
        </Row>
      </Card.Body>
    </Card>
  );
};

export default SupplierInformation;
