import React from "react";

// Assets
import Storage from "../../../assets/images/icons/storage.svg";
import Factory from "../../../assets/images/icons/factory.svg";
import Anchor from "../../../assets/images/icons/anchor.svg";
import Destination from "../../../assets/images/icons/destination.svg";

// Utils
import { convertTimeToDate } from "../../../utils";

const SeafoodJourneyDesktop = ({
  data,
  handleChangeActiveCard,
  activeCard,
  latlngStorage,
  latlngDeparture,
  latlngManufacture,
  latlngArrival
}) => {
  return (
    <>
      <div className="card-port-container">
        {/* Storage */}
        <div
          className={`mb-3 card-port ${
            activeCard === "storage" ? "active" : ""
          } `}
          onClick={() =>
            handleChangeActiveCard("storage", {
              lat: Number(latlngStorage.lat),
              lng: Number(latlngStorage.lng)
            })
          }
        >
          <p className="title mb-1">Storage</p>
          <div className="d-flex align-items-center mb-2">
            <img src={Storage} alt="Storage" className="me-2" />
            <p className="subtitle">
              {data.storage?.length > 20
                ? data.storage?.substring(0, 20) + "..."
                : data.storage}
            </p>
          </div>
          {data.storage_latlong && (
            <p className="subtitle">
              {latlngStorage.lat || "-"}, {latlngStorage.lng || "-"}
            </p>
          )}
          <p className="subtitle">
            {convertTimeToDate(data.storage_date).full}
          </p>
        </div>

        {/* Departure */}
        <div
          className={`mb-3 card-port ${
            activeCard === "port_of_departure" ? "active" : ""
          } `}
          onClick={() =>
            handleChangeActiveCard("port_of_departure", {
              lat: Number(latlngDeparture.lat),
              lng: Number(latlngDeparture.lng)
            })
          }
        >
          <p className="title mb-1">Port of Departure</p>
          <div className="d-flex align-items-center mb-2">
            <img src={Anchor} alt="Anchor" className="me-2" />
            <p className="subtitle">
              {data?.port_of_departure
                ? data.port_of_departure?.length > 20
                  ? data.port_of_departure?.substring(0, 20) + "..."
                  : data.port_of_departure
                : data.port_of_departure?.length > 20
                  ? data.port_of_departure?.substring(0, 20) + "..."
                  : data.port_of_departure}
            </p>
          </div>
          {data.port_of_departure_latlong && (
            <p className="subtitle">
              {latlngDeparture.lat || "-"}, {latlngDeparture.lng || "-"}
            </p>
          )}
          <p className="subtitle">
            {convertTimeToDate(data.actual_time_depature).full}
          </p>
        </div>
      </div>

      <div className="card-port-container">
        {/* Manufacture */}
        <div
          className={`mb-3 card-port ${
            activeCard === "manufacture" ? "active" : ""
          } `}
          onClick={() =>
            handleChangeActiveCard("manufacture", {
              lat: Number(latlngManufacture.lat),
              lng: Number(latlngManufacture.lng)
            })
          }
        >
          <p className="title mb-1">Manufacture</p>
          <div className="d-flex align-items-center mb-2">
            <img src={Factory} alt="Factory" className="me-2" />
            <p className="subtitle">
              {data.manufacture?.length > 20
                ? data.manufacture?.substring(0, 20) + "..."
                : data.manufacture}
            </p>
          </div>
          <p className="subtitle">
            {latlngManufacture.lat || "-"}, {latlngManufacture.lng || "-"}
          </p>
          <p className="subtitle">
            {convertTimeToDate(data.manufacture_date).full}
          </p>
        </div>

        {/* Destination */}
        <div
          className={`card-port ${
            activeCard === "destination" ? "active" : ""
          } `}
          onClick={() =>
            handleChangeActiveCard("destination", {
              lat: Number(latlngArrival.lat),
              lng: Number(latlngArrival.lng)
            })
          }
        >
          <p className="title mb-1">Destination</p>
          <div className="d-flex align-items-center mb-2">
            <img src={Destination} alt="Anchor" className="me-2" />
            <p className="subtitle">
              {data?.destination
                ? data.destination?.length > 20
                  ? data.destination?.substring(0, 20) + "..."
                  : data.destination
                : data.port_of_destination_name?.length > 20
                  ? data.port_of_destination_name?.substring(0, 20) + "..."
                  : data.port_of_destination_name}
            </p>
          </div>
          <p className="subtitle">
            {latlngArrival.lat || "-"}, {latlngArrival.lng || "-"}
          </p>
          <p className="subtitle">
            {convertTimeToDate(data.estimate_time_arrival).full}
          </p>
        </div>
      </div>
    </>
  );
};

export default SeafoodJourneyDesktop;
