import React, { useState, useEffect } from "react";
import { Card, Row, Col, Carousel } from "react-bootstrap";

// Assets
import Logo from "../../assets/images/FishLog Trace.svg";
import CrabMeat from "../../assets/images/crab meat.png";

import Storage from "../../assets/images/icons/storage.svg";
import Factory from "../../assets/images/icons/factory.svg";
import Anchor from "../../assets/images/icons/anchor.svg";
import Transfer from "../../assets/images/icons/transfer.svg";
import Ship from "../../assets/images/icons/ship.svg";
import Hook from "../../assets/images/icons/hook.svg";
import Destination from "../../assets/images/icons/destination.svg";

// Components
import MapComponent from "../../components/Map";
import PinIcon from "../../components/PinMap";
import LineMap from "../../components/LineMap";

// Utils
import { convertTimeToDate } from "../../utils";

const CardProduct = ({ data }) => {
  const [activeCard, setActiveCard] = useState(null);
  const [indexData, setIndexData] = useState(0);

  // Maps properties
  const [centerPoint, setCenterPoint] = useState({
    lat: -2.538384,
    lng: 113.468978
  });
  const [zoom, setZoom] = useState(5);

  // Lat long marker and line
  const [latlngCatching, setLatlngCatching] = useState({
    lat: null,
    lng: null
  });
  const [latlngLanding, setLatlngLanding] = useState({
    lat: null,
    lng: null
  });
  const [latlngStorage, setLatlngStorage] = useState({
    lat: null,
    lng: null
  });
  const [latlngManufacture, setLatlngManufacture] = useState({
    lat: null,
    lng: null
  });
  const [latlngDeparture, setLatlngDeparture] = useState({
    lat: null,
    lng: null
  });
  const [latlngArrival, setLatlngArrival] = useState({
    lat: null,
    lng: null
  });

  // Set lat long marker and line
  useEffect(() => {
    const catching = null;
    setLatlngCatching([
      {
        lat: catching,
        lng: catching
      }
    ]);

    const landing = data?.latlong_landing?.split(",");
    setLatlngLanding([
      {
        lat: landing ? landing[0] : null,
        lng: landing ? landing[1] : null
      }
    ]);

    const storage = data?.latlong_storage
      ? data?.latlong_storage
      : data?.latlong_storage_manufacture?.split(",");
    setLatlngStorage({
      lat: storage[0],
      lng: storage[1]
    });

    const manufacture = data?.latlong_manufacture
      ? data?.latlong_manufacture
      : data?.latlong_storage_manufacture?.split(",");
    setLatlngManufacture({
      lat: manufacture[0],
      lng: manufacture[1]
    });

    const port_of_departure = data?.latlong_departure_location
      ? data?.latlong_departure_location
      : data?.port_of_departure_latlong?.split(",");
    setLatlngDeparture({
      lat: port_of_departure[0],
      lng: port_of_departure[1]
    });

    const port_of_arrival = data?.latlong_arrival_location
      ? data?.latlong_arrival_location
      : data?.port_of_destination_latlong?.split(",");
    setLatlngArrival({
      lat: port_of_arrival[0],
      lng: port_of_arrival[1]
    });
  }, []);

  const handleChangeActiveCard = (value, latlng) => {
    if (value === activeCard) {
      setActiveCard(null);
      setCenterPoint(latlngArrival);
      setZoom(5);
    } else {
      setActiveCard(value);
      setCenterPoint({ lat: Number(latlng?.lat), lng: Number(latlng?.lng) });
      setZoom(12);
      setIndexData(0);
    }
  };

  const handleChangeActiveCardMultiple = (value) => {
    if (value === activeCard) {
      if (data?.batchs?.length > indexData) {
        for (let index = 0; index < data?.batchs.length; index++) {
          const item = data?.batchs[index];

          if (index === indexData) {
            if (value === "catching_area") {
              setCenterPoint({
                lat: Number(item?.catching_area?.latlong_catching_area[0]),
                lng: Number(item?.catching_area?.latlong_catching_area[1])
              });
            } else {
              setCenterPoint({
                lat: Number(item?.latlong_landing_area[0]),
                lng: Number(item?.latlong_landing_area[1])
              });
            }
            setZoom(12);
          }
        }

        setIndexData(indexData + 1);
      } else {
        setActiveCard(null);
        setCenterPoint(latlngArrival);
        setZoom(5);
        setIndexData(0);
      }
    } else {
      setActiveCard(value);

      if (data?.batchs?.length > 0) {
        for (let index = 0; index < data?.batchs.length; index++) {
          const item = data?.batchs[index];

          if (index === 0) {
            if (value === "catching_area") {
              setCenterPoint({
                lat: Number(item?.catching_area?.latlong_catching_area[0]),
                lng: Number(item?.catching_area?.latlong_catching_area[1])
              });
            } else {
              setCenterPoint({
                lat: Number(item?.latlong_landing_area[0]),
                lng: Number(item?.latlong_landing_area[1])
              });
            }
            setZoom(12);
          }
        }

        setIndexData(1);
      }
    }
  };

  return (
    <div className="card-product">
      <div className="card-product-container">
        <div className="card-product-header">
          <div className="d-flex align-items-center w-100 justify-content-center">
            <img height="40px" src={Logo} alt="logo fishlog" />
          </div>
        </div>

        <div className="card-product-body">
          <p className="title mb-3">Product Information</p>

          <div className="photo">
            {data.product_image ? (
              <Carousel pause="hover">
                {data.product_image?.map((item, index) => (
                  <Carousel.Item key={index}>
                    <img src={item} className="image" alt="carouselPhoto" />
                  </Carousel.Item>
                ))}
              </Carousel>
            ) : (
              <img
                src={data.attachment ?? CrabMeat}
                alt="product photos"
                className="image"
              />
            )}
          </div>

          <Card className="mt-3 mb-3 w-100">
            <Card.Body className="p-3">
              <Row className="product-info">
                <Col lg={6}>
                  <p className="title">
                    {data.product ? data.product : data.item_name}
                  </p>
                  <p className="subtitle">{`${data.product_type} - ${data.process}`}</p>

                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />
                  <Row className="list-text">
                    <Col lg={5} md={5} sm={5} xs={5}>
                      SKU Code
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1}>
                      :
                    </Col>
                    <Col>{data.sku ?? data?.sku_code}</Col>
                  </Row>
                  <Row className="list-text">
                    <Col lg={5} md={5} sm={5} xs={5}>
                      Item Name
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1}>
                      :
                    </Col>
                    <Col>{data.item_name}</Col>
                  </Row>
                  <Row className="list-text">
                    <Col lg={5} md={5} sm={5} xs={5}>
                      Expired Date
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1}>
                      :
                    </Col>
                    <Col>
                      {data.version === 2
                        ? convertTimeToDate(data?.expired_date)?.full
                        : data?.expired_date}
                    </Col>
                  </Row>
                  <Row className="list-text">
                    <Col lg={5} md={5} sm={5} xs={5}>
                      Manufacture Date
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1}>
                      :
                    </Col>
                    <Col>
                      {data.version === 2
                        ? convertTimeToDate(data?.manufacture_date)?.full
                        : data.production_date}
                    </Col>
                  </Row>
                  <Row className="list-text">
                    <Col lg={5} md={5} sm={5} xs={5}>
                      Process
                    </Col>
                    <Col lg={1} md={1} sm={1} xs={1}>
                      :
                    </Col>
                    <Col>{data.process}</Col>
                  </Row>
                </Col>
                <Col></Col>
                <Col lg={5}>
                  <p className="semi-title mt-sm-3 mt-lg-0">Raw Material</p>
                  <hr style={{ color: "#EEEEEE", opacity: "unset" }} />

                  {data.batch ? (
                    <div className="card-raw-material mb-3">
                      <Row className="list-text d-flex align-items-center">
                        <Col lg={5} md={5} sm={5} xs={5}>
                          Batch Number
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col>{data.batch}</Col>
                      </Row>
                      <Row className="list-text">
                        <Col lg={5} md={5} sm={5} xs={5}>
                          Inbound Date
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col>
                          {data.version === 2
                            ? convertTimeToDate(data.receive_date)?.full
                            : data.receive_date}
                        </Col>
                      </Row>
                      <Row className="list-text">
                        <Col lg={5} md={5} sm={5} xs={5}>
                          Catching Method
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col>{data.catching_method}</Col>
                      </Row>
                      <Row className="list-text">
                        <Col lg={5} md={5} sm={5} xs={5}>
                          Catching Area
                        </Col>
                        <Col lg={1} md={1} sm={1} xs={1}>
                          :
                        </Col>
                        <Col>{data.catch_area}</Col>
                      </Row>
                    </div>
                  ) : (
                    <div className="container-raw-material">
                      {data.batchs?.length > 0 ? (
                        <>
                          {data.batchs?.map((item) => (
                            <div
                              key={item.batch}
                              className="card-raw-material mb-3"
                            >
                              <Row className="list-text d-flex align-items-center">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                  Batch Number
                                </Col>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                  :
                                </Col>
                                <Col>{item.batch}</Col>
                              </Row>
                              <Row className="list-text">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                  Inbound Date
                                </Col>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                  :
                                </Col>
                                <Col>
                                  {data.version === 2
                                    ? convertTimeToDate(item.inbound_date)?.full
                                    : item.inbound_date}
                                </Col>
                              </Row>
                              <Row className="list-text">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                  Catching Method
                                </Col>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                  :
                                </Col>
                                <Col>{item.catching_method}</Col>
                              </Row>
                              <Row className="list-text">
                                <Col lg={5} md={5} sm={5} xs={5}>
                                  Catching Area
                                </Col>
                                <Col lg={1} md={1} sm={1} xs={1}>
                                  :
                                </Col>
                                <Col>{item.catching_area.name}</Col>
                              </Row>
                            </div>
                          ))}
                        </>
                      ) : (
                        <Row className="list-text">
                          <Col lg={5} md={5} sm={5} xs={5}>
                            N/A
                          </Col>
                        </Row>
                      )}
                    </div>
                  )}
                </Col>
              </Row>
            </Card.Body>
          </Card>

          <hr style={{ color: "#EEEEEE", opacity: "unset", width: "100%" }} />

          <p className="title mb-3 mt-3">Your Seafood Journey</p>
          <Card className="mb-3 w-100">
            <Card.Body className="p-3">
              <div className="seafood-journey">
                <div className="map me-3 mb-3 mb-lg-0">
                  <MapComponent
                    center={{
                      latitude: Number(centerPoint.lat),
                      longitude: Number(centerPoint.lng)
                    }}
                    zoomMaps={zoom}
                    height={"620px"}
                  >
                    {data?.batchs ? (
                      data?.batchs?.map((item, index) => (
                        <div key={index}>
                          <PinIcon
                            type="catching"
                            position={{
                              lat: Number(
                                item?.catching_area?.latlong_catching_area[0]
                              ),
                              lng: Number(
                                item?.catching_area?.latlong_catching_area[1]
                              )
                            }}
                          />
                          <LineMap
                            line={[
                              {
                                lat: Number(
                                  item?.catching_area?.latlong_catching_area[0]
                                ),
                                lng: Number(
                                  item?.catching_area?.latlong_catching_area[1]
                                )
                              },
                              {
                                lat: Number(item?.latlong_landing_area[0]),
                                lng: Number(item?.latlong_landing_area[1])
                              }
                            ]}
                          />

                          <PinIcon
                            type="landing"
                            position={{
                              lat: Number(item?.latlong_landing_area[0]),
                              lng: Number(item?.latlong_landing_area[1])
                            }}
                          />

                          <LineMap
                            line={[
                              {
                                lat: Number(item?.latlong_landing_area[0]),
                                lng: Number(item?.latlong_landing_area[1])
                              },
                              {
                                lat: Number(latlngStorage.lat),
                                lng: Number(latlngStorage.lng)
                              }
                            ]}
                          />
                        </div>
                      ))
                    ) : (
                      <>
                        {latlngCatching.lat && (
                          <>
                            <PinIcon
                              type="catching"
                              position={{
                                lat: Number(latlngCatching.lat),
                                lng: Number(latlngCatching.lng)
                              }}
                            />
                            <LineMap
                              line={[
                                {
                                  lat: Number(latlngCatching.lat),
                                  lng: Number(latlngCatching.lng)
                                },
                                {
                                  lat: Number(latlngLanding.lat),
                                  lng: Number(latlngLanding.lng)
                                }
                              ]}
                            />
                          </>
                        )}

                        <PinIcon
                          type="landing"
                          position={{
                            lat: Number(latlngLanding.lat),
                            lng: Number(latlngLanding.lng)
                          }}
                        />

                        <LineMap
                          line={[
                            {
                              lat: Number(latlngLanding.lat),
                              lng: Number(latlngLanding.lng)
                            },
                            {
                              lat: Number(latlngStorage.lat),
                              lng: Number(latlngStorage.lng)
                            }
                          ]}
                        />
                      </>
                    )}

                    <PinIcon
                      type="storage"
                      position={{
                        lat: Number(latlngStorage.lat),
                        lng: Number(latlngStorage.lng)
                      }}
                    />

                    <LineMap
                      line={[
                        {
                          lat: Number(latlngStorage.lat),
                          lng: Number(latlngStorage.lng)
                        },
                        {
                          lat: Number(latlngManufacture.lat),
                          lng: Number(latlngManufacture.lng)
                        }
                      ]}
                    />

                    <PinIcon
                      type="manufacture"
                      position={{
                        lat: Number(latlngManufacture.lat),
                        lng: Number(latlngManufacture.lng)
                      }}
                    />

                    <LineMap
                      line={[
                        {
                          lat: Number(latlngManufacture.lat),
                          lng: Number(latlngManufacture.lng)
                        },
                        {
                          lat: Number(latlngDeparture.lat),
                          lng: Number(latlngDeparture.lng)
                        }
                      ]}
                    />

                    <PinIcon
                      type="departure"
                      position={{
                        lat: Number(latlngDeparture.lat),
                        lng: Number(latlngDeparture.lng)
                      }}
                    />

                    <LineMap
                      line={[
                        {
                          lat: Number(latlngDeparture.lat),
                          lng: Number(latlngDeparture.lng)
                        },
                        {
                          lat: Number(latlngArrival.lat),
                          lng: Number(latlngArrival.lng)
                        }
                      ]}
                    />

                    <PinIcon
                      type="destination"
                      position={{
                        lat: Number(latlngArrival.lat),
                        lng: Number(latlngArrival.lng)
                      }}
                    />
                  </MapComponent>
                </div>

                <div className="card-port-container">
                  {data.batch?.length > 0 || data.batchs?.length > 0 ? (
                    <>
                      <div
                        className={`mb-3 card-port ${
                          activeCard === "catching_area" ? "active" : ""
                        } `}
                        onClick={() =>
                          handleChangeActiveCardMultiple("catching_area")
                        }
                      >
                        <p className="title mb-1">Catching Area</p>
                        <div className="d-flex align-items-center mb-2">
                          <img src={Hook} alt="Hook" className="me-2" />
                          {data.catch_area ? (
                            data.catch_area?.length > 20 ? (
                              <p className="subtitle">
                                {data.catch_area?.substring(0, 20) + "..."}{" "}
                              </p>
                            ) : (
                              <p className="subtitle">{data.catch_area}</p>
                            )
                          ) : (
                            <div className="d-flex flex-column">
                              {data?.batchs?.map((item, index) => {
                                if (index < 3) {
                                  return (
                                    <p className="subtitle" key={index}>
                                      {item?.catching_area.name?.length > 20
                                        ? item?.catching_area.name?.substring(
                                            0,
                                            20
                                          ) + "..."
                                        : item?.catching_area.name}
                                    </p>
                                  );
                                } else if (index === 3) {
                                  return (
                                    <p className="subtitle" key={index}>
                                      {data?.batchs?.length - 3} more
                                    </p>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </div>

                      <div
                        className={`mb-3 card-port ${
                          activeCard === "port_of_landing" ? "active" : ""
                        } `}
                        onClick={() =>
                          handleChangeActiveCardMultiple("port_of_landing")
                        }
                      >
                        <p className="title mb-1">Port of Landing</p>
                        <div className="d-flex align-items-center mb-2">
                          <img src={Ship} alt="Ship" className="me-2" />
                          {data.port_of_landing ? (
                            data.port_of_landing?.length > 20 ? (
                              <p className="subtitle">
                                {data.port_of_landing?.substring(0, 20) + "..."}
                              </p>
                            ) : (
                              <p className="subtitle">{data.port_of_landing}</p>
                            )
                          ) : (
                            <div className="d-flex flex-column">
                              {data?.batchs?.map((item, index) => {
                                if (index < 3) {
                                  return (
                                    <p className="subtitle" key={index}>
                                      {item?.landing_area?.length > 20
                                        ? item?.landing_area?.substring(0, 20) +
                                          "..."
                                        : item?.landing_area}
                                    </p>
                                  );
                                } else if (index === 3) {
                                  return (
                                    <p className="subtitle" key={index}>
                                      {data?.batchs?.length - 3} more
                                    </p>
                                  );
                                } else {
                                  return "";
                                }
                              })}
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  ) : (
                    ""
                  )}

                  {/* Storage */}
                  <div
                    className={`mb-3 card-port ${
                      activeCard === "storage" ? "active" : ""
                    } `}
                    onClick={() =>
                      handleChangeActiveCard("storage", {
                        lat: Number(latlngStorage.lat),
                        lng: Number(latlngStorage.lng)
                      })
                    }
                  >
                    <p className="title mb-1">Storage</p>
                    <div className="d-flex align-items-center mb-2">
                      <img src={Storage} alt="Storage" className="me-2" />
                      <p className="subtitle">
                        {data.storage?.length > 20
                          ? data.storage?.substring(0, 20) + "..."
                          : data.storage}
                      </p>
                    </div>
                    <p className="text-1">
                      {data.version === 2
                        ? convertTimeToDate(data?.batchs[0]?.inbound_date)?.full
                        : data.version === 1
                          ? data.receive_date
                          : "-"}
                    </p>
                  </div>

                  {/* Manufacture */}
                  <div
                    className={`mb-3 card-port ${
                      activeCard === "manufacture" ? "active" : ""
                    } `}
                    onClick={() =>
                      handleChangeActiveCard("manufacture", {
                        lat: Number(latlngManufacture.lat),
                        lng: Number(latlngManufacture.lng)
                      })
                    }
                  >
                    <p className="title mb-1">Manufacture</p>
                    <div className="d-flex align-items-center mb-2">
                      <img src={Factory} alt="Factory" className="me-2" />
                      <p className="subtitle">
                        {data.manufacture?.length > 20
                          ? data.manufacture?.substring(0, 20) + "..."
                          : data.manufacture}
                      </p>
                    </div>
                    <p className="text-1">
                      {data.version === 2
                        ? convertTimeToDate(data?.manufacture_date)?.full
                        : data.version === 1
                          ? data.production_date
                          : "-"}
                    </p>
                  </div>

                  {/* Departure */}
                  <div
                    className={`mb-3 card-port ${
                      activeCard === "port_of_departure" ? "active" : ""
                    } `}
                    onClick={() =>
                      handleChangeActiveCard("port_of_departure", {
                        lat: Number(latlngDeparture.lat),
                        lng: Number(latlngDeparture.lng)
                      })
                    }
                  >
                    <p className="title mb-1">Port of Departure</p>
                    <div className="d-flex align-items-center mb-2">
                      <img src={Anchor} alt="Anchor" className="me-2" />
                      <p className="subtitle">
                        {data?.location_of_departure_name
                          ? data.location_of_departure_name?.length > 20
                            ? data.location_of_departure_name?.substring(
                                0,
                                20
                              ) + "..."
                            : data.location_of_departure_name
                          : data.port_of_departure_name?.length > 20
                            ? data.port_of_departure_name?.substring(0, 20) +
                              "..."
                            : data.port_of_departure_name}
                      </p>
                    </div>
                    <p className="text-1">
                      {data?.actual_time_depture
                        ? convertTimeToDate(data?.actual_time_depture)?.full
                        : "-"}
                    </p>
                  </div>

                  {/* Destination */}
                  <div
                    className={`card-port ${
                      activeCard === "destination" ? "active" : ""
                    } `}
                    onClick={() =>
                      handleChangeActiveCard("destination", {
                        lat: Number(latlngArrival.lat),
                        lng: Number(latlngArrival.lng)
                      })
                    }
                  >
                    <p className="title mb-1">Destination</p>
                    <div className="d-flex align-items-center mb-2">
                      <img src={Destination} alt="Anchor" className="me-2" />
                      <p className="subtitle">
                        {data?.location_of_arrival_name
                          ? data.location_of_arrival_name?.length > 20
                            ? data.location_of_arrival_name?.substring(0, 20) +
                              "..."
                            : data.location_of_arrival_name
                          : data.port_of_destination_name?.length > 20
                            ? data.port_of_destination_name?.substring(0, 20) +
                              "..."
                            : data.port_of_destination_name}
                      </p>
                    </div>
                    <p className="text-1">
                      {data?.estimate_time_arrival
                        ? convertTimeToDate(data?.estimate_time_arrival)?.full
                        : "-"}
                    </p>
                  </div>
                </div>
              </div>
            </Card.Body>
          </Card>

          <hr style={{ color: "#EEEEEE", opacity: "unset", width: "100%" }} />

          <p className="title mb-3 mt-3">Delivery Information</p>
          <Card className="w-100">
            <Card.Body className="p-3 d-flex justify-content-center gap-5">
              <div className="d-flex flex-column align-items-start">
                <p className="subtitle">Origin</p>
                <p className="text-2">
                  {data.port_of_departure_code ?? data.loc_departure_code}
                </p>
              </div>
              <div className="d-flex flex-column align-items-center">
                <img src={Transfer} alt="Transfer" />
                <p className="subtitle mt-2">
                  {data.vessel ?? data.vessel_code}
                </p>
              </div>
              <div className="d-flex flex-column align-items-end">
                <p className="subtitle">Destination</p>
                <p className="text-2">
                  {data.port_of_destination_code ?? data.loc_arrival_code}
                </p>
              </div>
            </Card.Body>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default CardProduct;
